import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvConfig } from './model';
import { AuthConfig } from '@auth0/auth0-angular/lib/auth.config';
import { AuthClientConfig } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private envConfig: EnvConfig = {
    tierLoginUrl: '',
    clientId: '',
    idpOrganizationId: '',
    redirectPath: '',
    audience: ''
  };

  constructor(private http: HttpClient) {}

  isLocal() {
    const domain = window.location.hostname.split('.').slice(-2).join('.')
    return domain == `test.local-vodori.com`;
  }

  getConfig(): Observable<EnvConfig> {
    const subdomain: string = document.location.host.split('.')[0];
    const domainArray: string[] = window.location.hostname.split('.');
    let domain: string = domainArray.slice(-2).join('.');

    if (this.isLocal()) {
      domain = 'dev-vodori.com';
    }

    const params: HttpParams = new HttpParams()
    .set('slug', subdomain)
    .set('domain', domain);

    return this.http.get<EnvConfig>(
      `https://platform-api.vodori.${domain}/api/config`,
      { params }
    );
  }

  loadConfig(authConfig: AuthClientConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getConfig().subscribe({
        next: (config: EnvConfig) => {
          this.envConfig = config;

          const newAuthConfig: AuthConfig = this.prepareAuthConfig();
          authConfig.set(newAuthConfig);
          resolve(true);
        },
        error: (error) => {
          console.error('Error loading configuration:', error);
          reject(error);
        }
      });
    });
  }

  prepareAuthConfig(): AuthConfig {
    return {
      domain: this.envConfig.tierLoginUrl,
      clientId: this.envConfig.clientId,
      authorizationParams: {
        organization: this.envConfig.idpOrganizationId,
        prompt: 'select_account',
        audience: this.envConfig.audience,
        redirect_uri: window.location.origin + '/login',
      },
    };
  }

}
