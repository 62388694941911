import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthClientConfig, AuthGuard, AuthModule } from '@auth0/auth0-angular';
import { ConfigService } from './core/config/configService';
import { HttpClientModule } from '@angular/common/http';
import { RedirectComponent } from "./redirect/redirect.component";

const configFactory = (configService: ConfigService, authConfig: AuthClientConfig) => {
  return () => configService.loadConfig(authConfig);
};

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AuthModule.forRoot()
  ],
  providers: [
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      multi: true,
      deps: [ConfigService, AuthClientConfig]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
