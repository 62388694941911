import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription, combineLatest, filter, switchMap } from 'rxjs';
import { AppUtils } from './core/utils/app.utils';
import { COOKIE } from './core/utils/app.constants';
import { jwtDecode } from 'jwt-decode';
import { ClaimTenant, VodoriJwt } from './core/utils/app.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    console.log('==> Login App: Init');

    this.subscription.add(
      this.authService.isAuthenticated$.subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          console.log('==> Login App: User NOT authenticated. Removing all possible jwt cookies.');
          AppUtils.getCookie(COOKIE.CLOUD_TNTS)?.split(',').forEach(tnt => {
            const et = tnt.split('/');
            AppUtils.deleteCookie(COOKIE.CLOUD_JWT, `/${et[0]}/cloud/${et[1]}/flow-config`);
          })
          AppUtils.deleteCookie(COOKIE.CLOUD_TNTS);
        }
      })
    );

    this.subscription.add(
      combineLatest([
        this.authService.isLoading$,
        this.authService.isAuthenticated$,
      ]).pipe(
        filter(([isLoading, isAuthenticated]) => !isLoading && isAuthenticated),
        switchMap(_ => {
          console.log('==> Login App: Authenticated user, fetching access token');
          return combineLatest([
            this.authService.user$,
            this.authService.getAccessTokenSilently()
          ]);
        })
      ).subscribe(([user, jwtToken]) => {
        console.log('==> Login App: User authenticated');

        const cloudCallbackUri = AppUtils.getCookie(COOKIE.CLOUD_CALLBACK_URI);
        if (cloudCallbackUri) {
          console.log(`==> Login App: Redirect to cloudCallbackUri: ${cloudCallbackUri}`);
          window.location.replace(window.origin + cloudCallbackUri);
        } else {
          console.log(`==> Login App: No cloudCallbackUri cookie. Using defaults: ${cloudCallbackUri}`);
          const decodedToken: any = jwtDecode(jwtToken);
          const defaults: string = decodedToken['https://vodori.com/def'];
          this.setEnvironmentsCookie(decodedToken);
          if (defaults != null) {
            console.log('==> Login App: Default values are populated, redirecting.')
            window.location.replace(`${window.origin}/${defaults}`);
          } else {
            console.log('==> Login App: cloudCallbackUri and no defaults.')
            window.location.replace(`${window.origin}/error/error.html?errorCode=403`);
          }
        }
      })
    );
  }

  setEnvironmentsCookie(decodedToken: VodoriJwt) {
    const tenants: ClaimTenant[] = decodedToken['https://vodori.com/tnts'];
    const ets = tenants.map(ten => (ten.et)).join(',')
    AppUtils.setCookie(COOKIE.CLOUD_TNTS, ets);
  }
}
